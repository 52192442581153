/* eslint-disable no-const-assign */
import moment from 'moment-timezone'
import { startOfDay, endOfDay, addDays, subDays } from 'date-fns'

import { regexCodeArea, regeUnitNumber, unitNumberCodeRegexGlobal } from 'admin/utils/regex'
import { TAG_CODE } from 'admin/config'

export const appRoot = document.getElementById('app-root')

const numberFormat = new Intl.NumberFormat('id-ID', { style: 'decimal', maximumFractionDigits: 2 })
export const formatNumber = (num) => {
  const number = Number(num)
  return numberFormat.format(number)
}

export const createDateFromTextValue = (value) => new Date(1970, 1, 1, value, '00')

// https://momentjscom.readthedocs.io/en/latest/moment/04-displaying/01-format/
export const formatDate = (time) => moment(time).format('DD/MM/YYYY')
export const formatTimeOnlyHour = (time) => moment(time).format('HH')
export const formatDayName = (time) => moment(time).format('ddd, DD/MM/YYYY')
export const formatDayOnly = (time) => moment(time).format('dddd')
export const formatDateHour = (time) => moment(time).format('DD/MM/YYYY HH:mm')
export const formatMonthDate = (time) => moment(time).format('MMM D')
export const formatTimeOnly = (time) => moment(time).format('h:mm a')
export const formatDateHourAmPm = (time) => moment(time).format('DD/MM/YYYY, h:mm a')
export const formatDateTimeSeconds = (time) => moment(time).format('DD/MM/YYYY, h:mm:ss a')
export const formatMonthShort = (time) => moment(time).format('DD MMM (ddd)')

export const getDateList = (startDate, length = 7) => {
  const dates = []
  for (let i = 0; i < length; i++) {
    dates.push(moment(startDate).add(i, 'days').startOf('day'))
  }
  return dates
}

export const TODAY_MOMENT = moment().startOf('day')
export const isTodayDate = (date) => moment(date).isSame(TODAY_MOMENT)
export const isBeforeToday = (date) => moment(date).isBefore(TODAY_MOMENT)
export const tomorrow = moment().add(1, 'days').startOf('day')
export const yesterday = (date) => moment(date).subtract(1, 'day')

export const TODAY = () => new Date()
export const aWeekAgo = startOfDay(subDays(TODAY(), 7))
export const aWeekAhead = endOfDay(addDays(TODAY(), 7))

export const prevDate = (date, amount = 1) => startOfDay(subDays(new Date(date), amount))
export const nextDate = (date, amount = 1) => startOfDay(addDays(new Date(date), amount))

// last Sunday - Saturday
export const lastSunday = startOfDay(new Date(moment().subtract(1, 'weeks').startOf('week')))
export const lastSaturday = endOfDay(new Date(moment().subtract(1, 'weeks').endOf('week')))

export const getTags = (bookingTags) => {
  const pickup = []
  const delivery = []
  if (bookingTags) {
    bookingTags.map((bookingTag) => {
      if (bookingTag.tag && bookingTag.tag.name) {
        let tagLabel = bookingTag.tag.name
        if (
          // posCode for 'Curtain (Dismantling)' is Curtain
          bookingTag.tag.posCode === TAG_CODE.CURTAIN_WITH_DISMANTLING &&
          bookingTag.additionalInfo?.numberOfRoom
        ) {
          tagLabel = `${tagLabel} - ${bookingTag.additionalInfo.numberOfRoom} room`
        }
        if (bookingTag.type === 'pickup') {
          pickup.push(tagLabel)
        } else {
          delivery.push(tagLabel)
        }
      }
      return true
    })
  }
  return {
    pickup,
    delivery,
  }
}

export const groupByArrToObj = (objectArray, property) => {
  return objectArray.reduce((acc, obj) => {
    const key = obj[property]
    if (!acc[key]) {
      acc[key] = []
    }
    acc[key].push(obj)
    return acc
  }, {})
}
// holiday setting calendar
export const defaultDate = (year) => moment(`01-01-${year}`, 'DD-MM-YYYY').toDate()
export const minDate = (year) => moment(`01-01-${year}`, 'DD-MM-YYYY').toDate()
export const maxDate = (year) => moment(`31-12-${year}`, 'DD-MM-YYYY').toDate()

export const dateOnlyFormat = (string) => {
  const date = new Date(string)
  const year = date.getFullYear()
  const month = `0${date.getMonth() + 1}`.slice(-2)
  const day = `0${date.getDate()}`.slice(-2)
  return `${year}-${month}-${day}`
}

const getMax = (a, b) => {
  return Math.max(a, b)
}

const priorityMaxValue = (arr) => {
  const bookingTags = arr.filter((item) => item.tag !== null && !!item.tagId)
  const priorityList = bookingTags.map((bookingTag) => bookingTag.tag.priority)
  return priorityList.reduce(getMax, 0)
}

export const priority = (data) => {
  if (data.length > 0) {
    const tagPriority = data.length === 1 ? data[0]?.tag?.priority : priorityMaxValue(data)
    return tagPriority
  }
  return 0
}

const maxValue = (arr) => {
  // filter out deleted tags
  const bookingTags = arr.filter((item) => item.tag !== null && !!item.tagId)
  const weightageList = bookingTags.map((bookingTag) => bookingTag.tag.weightage)
  return weightageList.reduce(getMax, 0)
}

export const weightage = (data) => {
  if (data.bookingTags.length > 0) {
    const weight =
      data.bookingTags.length === 1 ?
        data.bookingTags[0]?.tag?.weightage :
        maxValue(data.bookingTags)
    return weight
  }
  return 0
}

export const slotDisplay = (start, end) => {
  if (!start) return null
  if (start.length === 2) return `${start}-${end}`
  return `${start.slice(0, 2)}-${end.slice(0, 2)}`
}

export const channelDisplay = (name) => {
  if (!name) return '-'
  return name.split('_')[0]
}

export const joinText = (data) => data.map((text) => text).join(', ')

export const groupBy = (array, key) => {
  return array.reduce((result, currentValue) => {
    (result[currentValue[key]] = result[currentValue[key]] || []).push(currentValue)
    return result
  }, {})
}

export const getFormattedTime = (hours) => {
  const hour = hours % 12 || 12
  const hourAmPm = hours < 12 ? `${hour}am` : `${hour}pm`
  return hourAmPm
}

export const validpostalCode = (address) => {
  if (!address) return ''
  const result = regexCodeArea.exec(address)
  if (!result) return ''
  return result[0]
}

export const validUnitNumber = (address) => {
  if (!address) return ''
  const result = regeUnitNumber.exec(address)
  if (!result) return ''
  return result[0]
}

export const withoutUnitNumber = (address) => {
  if (!address) return ''
  const result = address.replace(unitNumberCodeRegexGlobal, '')
  return result.trim()
}

export const getSelectedDate = ({ start, dayToSkip, holidayStoreList, type = 'default' }) => {
  let dateStartOfDay
  if (dayToSkip) {
    dateStartOfDay = moment().add(dayToSkip, 'days').startOf('day')
  }
  if (start) {
    dateStartOfDay = moment(start).startOf('day')
  }

  const dateToCompare = dateOnlyFormat(dateStartOfDay)

  let findHoliday
  if (formatDayOnly(dateStartOfDay) === 'Sunday') {
    findHoliday = true
  } else {
    const checkHoliday = holidayStoreList.find(
      (holiday) => dateToCompare === dateOnlyFormat(holiday.date),
    )
    findHoliday = !!checkHoliday
  }

  if (findHoliday) {
    if (type === 'default') {
      const increaseDayToSkip = dayToSkip + 1
      return getSelectedDate({
        dayToSkip: increaseDayToSkip,
        holidayStoreList,
      })
    }
    if (type === 'prev') {
      return getSelectedDate({
        start: startOfDay(subDays(new Date(start), 1)),
        holidayStoreList,
        type: 'prev',
      })
    }
    if (type === 'next') {
      return getSelectedDate({
        start: startOfDay(addDays(new Date(start), 1)),
        holidayStoreList,
        type: 'next',
      })
    }
  }
  return dateToCompare
}

export const getDriverInfo = (type) => {
  const info = {
    label: '',
    imgSource: '',
  }
  if (type === 'homePickupDriver') {
    info.label = 'Home pick-up driver'
    info.imgSource = '/images/homepickup-driver.png'
  } else if (type === 'outletDriver') {
    info.label = 'Outlet driver'
    info.imgSource = '/images/outlet-driver.png'
  }
  return info
}

// set disable checkbox when sms already send at 12pm sg time
export const isDisableCheck = () => {
  const formatDateTime1 = moment(new Date())
  const formatDateTime2 = moment().set('hour', 12)
  return formatDateTime1.isSameOrAfter(formatDateTime2)
}

export const replaceToNumbers = (string) => {
  return string.replace(/\D/g, '')
}

export const replaceToString = (string) => {
  return string.replace(/[^\w\s]/gi, '')
}

export const bookedListAWeekAgo = (bookingStoreList, listAWeekAgo, driverId, slotId) => {
  return listAWeekAgo.filter((bookingFilter) => {
    return (
      bookingFilter.driverId === driverId &&
      bookingFilter.timeslotId === slotId &&
      bookingFilter.status !== 'failed' &&
      bookingFilter.status !== 'completed' &&
      bookingFilter.bookingTags.some(({ type }) => type === 'pickup') &&
      !bookingStoreList.some((item) => item.customerId === bookingFilter.customerId)
    )
  })
}

export const mergeList = (listAWeekAgo, currentList) => {
  const filter = listAWeekAgo.filter((list) => {
    return (
      list.status !== 'failed' &&
      list.status !== 'completed' &&
      list.bookingTags.some(({ type }) => type === 'pickup') &&
      !currentList.some((item) => item.customerId === list.customerId)
    )
  })

  const listAWeekAgoNew = filter.map((item) => {
    return {
      isReserve: true,
      ...item,
    }
  })

  const currentListNew = currentList.map((item) => {
    return {
      ...item,
      isReserve: false,
    }
  })
  return [...currentListNew, ...listAWeekAgoNew]
}

// https://stackoverflow.com/a/23921775
export const moveAnytimeToFront = (a, b) => {
  if (Number(a.start) === 8 && Number(a.end) > 10) {
    return -1
  }
  if (Number(b.start) === 8 && Number(b.end) > 0) {
    return 1
  }
  return 0
}

const regexPostalCode = /\d{6}/
export const extractPostalCodeFromAddress = (address) => {
  if (!address) return ''
  const result = regexPostalCode.exec(address)
  if (!result) return ''
  return result[0]
}
